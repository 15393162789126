import { MapObj } from '@types';

enum UserPermissionTypes {
  BRANCH_CREATE = 'branch_create',
  BRANCH_VIEW = 'branch_view',
  BRANCH_EDIT = 'branch_edit',
  GROUP_VIEW = 'group_view',
  GROUP_CREATE = 'group_create',
  GROUP_EDIT = 'group_edit',
  GROUP_DISABLE = 'group_disable',
  GROUP_MEMBER_CREATE = 'group_member_create',
  GROUP_MEMBER_EDIT = 'group_member_edit',
  GROUP_MEMBER_VIEW = 'group_member_view',
  ORG_ADMIN_CREATE = 'organisation_admin_create',
  ORG_ADMIN_EDIT = 'organisation_admin_edit',
  ORG_ADMIN_VIEW = 'organisation_admin_view',
  BRANCH_ADMIN_CREATE = 'branch_admin_create',
  BRANCH_ADMIN_EDIT = 'branch_admin_edit',
  BRANCH_ADMIN_VIEW = 'branch_admin_view',
  GROUP_ADMIN_CREATE = 'group_admin_create',
  GROUP_ADMIN_EDIT = 'group_admin_edit',
  GROUP_ADMIN_VIEW = 'group_admin_view',
  FINANCE_ADMIN_CREATE = 'finance_create',
  FINANCE_ADMIN_EDIT = 'finance_edit',
  FINANCE_ADMIN_VIEW = 'finance_view',
  OWNER_VIEW = 'owner_view',
  PAYMENT_ORDER_GENERATE_REPORT = 'payment_order_generate_report',
  PAYMENT_ORDER_VIEW = 'payment_order_view',
  PAYMENT_ORDER_SEND_REMINDER = 'payment_order_send_reminder',
  PAYMENT_ORDER_EDIT = 'payment_order_edit',
  ORGANIZATION_LIST_VIEW = 'organisation_list_view',
  ORGANIZATION_DETAILS_VIEW = 'organisation_details_view',
  ORGANIZATION_STATUS_CHANGE = 'organisation_status_change',
  KYC_DOCUMENT_VIEW = 'kyc_document_view',
  KYC_DOCUMENT_STATUS_CHANGE = 'kyc_document_status_change',
  GROUP_ATTENDANCE_VIEW = 'group_attendance_view',
  GROUP_ATTENDANCE_EDIT = 'group_attendance_edit',
  GROUP_ATTENDANCE_REPORT_DOWNLOAD = 'group_attendance_report_download',
  DASHBOARD_VIEW = 'dashboard_view',
  SUBSCRIPTION_VIEW = 'subscription_view',
  PAYMENT_ORDER_GENERATE_GST_REPORT = 'payment_order_generate_gst_report'
}

export default UserPermissionTypes;

// Page pathname & corresponding permission for page access
// By default, page will be accessible to all
export const pageMap: MapObj = {
  '/branches/add': [UserPermissionTypes.BRANCH_CREATE],
  '/branches': [UserPermissionTypes.BRANCH_VIEW],
  '/groups/add': [UserPermissionTypes.GROUP_CREATE],
  '/groups': [UserPermissionTypes.GROUP_VIEW],
  '/team-management': [
    UserPermissionTypes.ORG_ADMIN_VIEW,
    UserPermissionTypes.OWNER_VIEW,
    UserPermissionTypes.BRANCH_ADMIN_VIEW,
    UserPermissionTypes.GROUP_ADMIN_VIEW
  ],
  '/team-management/invite?role=organization_admin': [
    UserPermissionTypes.ORG_ADMIN_CREATE
  ],
  '/team-management/invite?role=branch_admin': [
    UserPermissionTypes.BRANCH_ADMIN_CREATE
  ],
  '/team-management/invite?role=group_admin': [
    UserPermissionTypes.GROUP_ADMIN_CREATE
  ],
  '/team-management/invite?role=finance': [
    UserPermissionTypes.FINANCE_ADMIN_CREATE
  ],
  '/organizations': [UserPermissionTypes.ORGANIZATION_LIST_VIEW],
  '/transactions': [UserPermissionTypes.PAYMENT_ORDER_VIEW],
  '/subscription-plan': [UserPermissionTypes.SUBSCRIPTION_VIEW],
  '/reports': [UserPermissionTypes.PAYMENT_ORDER_GENERATE_GST_REPORT]
};
