export const AUTH = {
  LOGIN: '/login',
  SELECT_ACCOUNT: '/select-account',
  SELECT_PAYMENT: '/select-payment',
  DOCUMENT_UPLOAD: '/document-upload',
  CREATE_BRANCH: '/create-branch',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  ACCEPT_INVITE: '/accept-invite',
  ORG_SELECTION: '/organization-selection'
};

export const HOME = '/';

export const GROUPS = {
  GROUPS: '/branches/:branchId/groups',
  ADD_NEW_GROUP: '/branches/:branchId/groups/add',
  GROUP_INFO: '/branches/:branchId/groups/:groupId',
  ADD_MEMBER: '/branches/:branchId/groups/:groupId/add-members',
  EDIT_GROUP: '/branches/:branchId/groups/:groupId/edit'
};

export const BRANCHES = {
  BRANCHES: '/branches',
  ADD_NEW_BRANCH: '/branches/add',
  EDIT_BRANCH: '/branches/edit/:branchId'
};

export const TEAM_MANAGEMENT = {
  TEAM_MANAGEMENT: '/team-management',
  INVITE_MEMBER: '/team-management/invite',
  EDIT_ROLE: '/team-management/edit-role/:userId'
};

export const ADMIN = {
  KYC_DOCUMENTS: '/kyc-documents',
  ORGANIZATION_DETAILS: '/kyc-documents/organization/:orgId',
  INDIVIDUAL_DETAILS: '/kyc-documents/individual/:individualId',
  VIEW_DOCUMENTS: '/kyc-documents/organization/:orgId/documents'
};

export const PAGE_NOT_FOUND = '/page-not-found';

export const ADMIN_PAGE_NOT_FOUND = '/admin/page-not-found';

export const HELP = '/help';

export const DASHBOARD = '/dashboard';

export const TRANSACTIONS = {
  TRANSACTIONS: '/transactions'
};

export const SUBSCRIPTION_PLAN = '/subscription-plan';

export const ORG_SETTINGS = '/org-settings';

export const REPORTS = '/reports';
