import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import {
  HTTPResponse,
  LoginRequest,
  Scalars,
  ValidateOTPRequest,
  VerifyBankDetailsRequest,
  VerifyGstinRequest,
  VerifyPanRequest,
  VerifyUpiRequest
} from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<HTTPResponse<any>, LoginRequest>({
      query: (credentials) => ({
        url: END_POINTS.AUTH.GENERATE_OTP,
        method: 'POST',
        body: credentials
      })
    }),
    validateOtp: builder.mutation<HTTPResponse<any>, ValidateOTPRequest>({
      query: (credentials) => ({
        url: END_POINTS.AUTH.VALIDATE_OTP,
        method: 'POST',
        body: credentials
      })
    }),
    logout: builder.query<HTTPResponse<any>, Scalars['QueryString']>({
      query: (userId) => ({
        url: `${END_POINTS.AUTH.LOGOUT}/${userId}/logout`,
        method: 'POST'
      })
    }),
    getUserDetails: builder.query<
      HTTPResponse<any>,
      { userId: string; query: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.AUTH.GET_USER_DETAILS(reqBody.userId)}?${
          reqBody.query
        }`,
        method: 'GET'
      })
    }),
    verifyUpi: builder.mutation<HTTPResponse<any>, VerifyUpiRequest>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.VERIFY_UPI(reqBody.userId),
        method: 'POST',
        body: reqBody.body
      })
    }),
    verifyBankDetails: builder.mutation<
      HTTPResponse<any>,
      VerifyBankDetailsRequest
    >({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.VERIFY_BANK_DETAILS(reqBody.userId),
        method: 'POST',
        body: reqBody.body
      })
    }),
    onboardUser: builder.mutation<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.ONBOARD_USER(reqBody.userId),
        method: 'PUT',
        body: reqBody.body
      })
    }),
    getAllUserInvitations: builder.query<
      HTTPResponse<any>,
      { query: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.AUTH.GET_ALL_USER_INVITATIONS}?${reqBody.query}`,
        method: 'GET'
      })
    }),
    verifyPan: builder.mutation<HTTPResponse<any>, VerifyPanRequest>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.VERIFY_PAN(reqBody.orgId),
        method: 'POST',
        body: reqBody.body
      })
    }),
    // TODO: can move to organizationQuery
    getOrganizationList: builder.query<HTTPResponse<any>, null>({
      query: () => ({
        url: END_POINTS.ORGANIZATION.GET_ORGANIZATION_LIST,
        method: 'GET'
      })
    }),
    getRolesAndPermissions: builder.query<HTTPResponse<any>, Scalars['String']>(
      {
        query: (orgId) => ({
          url: END_POINTS.AUTH.GET_ROLES_AND_PERMISSIONS(orgId),
          method: 'GET'
        })
      }
    ),
    onboardOrganization: builder.mutation<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.ONBOARD_ORGANIZATION(reqBody.organizationId),
        method: 'PATCH',
        body: reqBody.body
      })
    }),
    createOrganization: builder.mutation<HTTPResponse<any>, null>({
      query: () => ({
        url: END_POINTS.ORGANIZATION.CREATE_ORGANIZATION,
        method: 'POST'
      })
    }),
    getReUploadList: builder.query<HTTPResponse<any>, Scalars['String']>({
      query: (orgId) => ({
        url: END_POINTS.ORGANIZATION.GET_REUPLOAD_LIST(orgId),
        method: 'GET'
      })
    }),
    updateInvitation: builder.mutation<
      HTTPResponse<any>,
      { inviteId: string; body: { status: string } }
    >({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.UPDATE_INVITATION(reqBody.inviteId),
        method: 'PATCH',
        body: reqBody.body
      })
    }),
    verifyGstin: builder.mutation<HTTPResponse<any>, VerifyGstinRequest>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.VERIFY_GSTIN(reqBody.orgId),
        method: 'POST',
        body: reqBody.body
      })
    }),
    updateOrganizationById: builder.mutation<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: END_POINTS.ORGANIZATION.UPDATE_ORGANIZATION_BY_ID(reqBody.orgId),
        method: 'PATCH',
        body: reqBody.body
      })
    }),
    appConfig: builder.query<any, any>({
      query: () => ({
        url: END_POINTS.APP_CONFIG,
        method: 'GET'
      })
    }),
    upgradeLite: builder.mutation<
      HTTPResponse<any>,
      { orgId: string; body: any }
    >({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.UPGRADE_LITE(reqBody.orgId),
        method: 'POST',
        body: reqBody.body
      })
    }),
    getAllPlans: builder.query<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.GET_ALL_PLANS(reqBody.orgId),
        method: 'GET'
      })
    }),
    addSubscription: builder.mutation<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.ADD_SUBSCRIPTION(reqBody.orgId),
        body: reqBody.body,
        method: 'POST'
      })
    }),
    referralCodes: builder.mutation<
      HTTPResponse<any>,
      { orgId: string; referral_code: string }
    >({
      query: (reqBody) => ({
        url: END_POINTS.AUTH.REFERRAL_CODE(reqBody.orgId),
        body: reqBody,
        method: 'POST'
      })
    }),
    referralStatus: builder.query<HTTPResponse<any>, any>({
      query: (orgId) => ({
        url: END_POINTS.AUTH.REFERRAL_STATUS(orgId),
        method: 'GET'
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useValidateOtpMutation,
  useLazyLogoutQuery,
  useGetUserDetailsQuery,
  useLoginMutation,
  useVerifyUpiMutation,
  useOnboardUserMutation,
  useVerifyBankDetailsMutation,
  useGetAllUserInvitationsQuery,
  useVerifyPanMutation,
  useLazyGetOrganizationListQuery,
  useGetOrganizationListQuery,
  useGetRolesAndPermissionsQuery,
  useOnboardOrganizationMutation,
  useLazyGetRolesAndPermissionsQuery,
  useCreateOrganizationMutation,
  useGetReUploadListQuery,
  useLazyGetUserDetailsQuery,
  useUpdateInvitationMutation,
  useVerifyGstinMutation,
  useUpdateOrganizationByIdMutation,
  useLazyAppConfigQuery,
  useUpgradeLiteMutation,
  useLazyGetAllPlansQuery,
  useAddSubscriptionMutation,
  useReferralCodesMutation,
  useReferralStatusQuery
} = extendedBaseQuery;
