import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import {
  HTTPResponse,
  Scalars,
  Group,
  Member,
  UpdatemMemberApiResponse,
  UpdateGroupRequest,
  UpdateAttendanceStatus,
  OrgAndBranchId,
  OrgBranchGroupIds,
  OrgBranchGroupMemberIds,
  CreateMemberInExistingGroupRequestBody,
  UpdateMember,
  UpdateGroupStatusParams,
  DeleteMemberRequestBody
} from '@types';

import { DayType } from '../components/tabs/attendance-tab/constants';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query<
      HTTPResponse<Group>,
      { params: OrgAndBranchId; queryString: Scalars['QueryString'] }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_ALL_GROUPS(req.params)}${
          req.queryString
        }`,
        method: 'GET'
      })
    }),
    getGroupById: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupIds; queryString: Scalars['QueryString'] }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_GROUP_BY_ID(req.params)}${
          req.queryString
        }`
      })
    }),
    getGroupMembers: builder.query<
      HTTPResponse<Member[]>,
      { params: OrgBranchGroupIds; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_GROUP_MEMBERS(req.params)}${
          req.queryString
        }`,
        method: 'GET'
      })
    }),
    deleteGroupMember: builder.mutation<
      any,
      { params: OrgBranchGroupIds; body: DeleteMemberRequestBody }
    >({
      query: (req) => ({
        url: END_POINTS.GROUP_MANAGEMENT.DELETE_MEMBER(req.params),
        method: 'PATCH',
        body: req.body
      })
    }),
    updateGroupStatus: builder.mutation<
      HTTPResponse<any>,
      { params: UpdateGroupStatusParams }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.UPDATE_GROUP_STATUS(req.params)}`,
        method: 'PATCH'
      })
    }),
    updateMember: builder.mutation<
      HTTPResponse<UpdatemMemberApiResponse>,
      { params: OrgBranchGroupMemberIds; body: UpdateMember }
    >({
      query: (req) => ({
        url: END_POINTS.GROUP_MANAGEMENT.UPDATE_MEMBER(req.params),
        method: 'PUT',
        body: req.body
      })
    }),
    updateMemberStatus: builder.mutation<
      HTTPResponse<any>,
      { params: OrgBranchGroupMemberIds; body: { status: string } }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.UPDATE_MEMBER_STATUS(req.params)}`,
        method: 'PATCH',
        body: req.body
      })
    }),
    // To be moved to another file, as it a generic api
    imageUpload: builder.mutation<HTTPResponse<any>, any>({
      query: (credentials) => ({
        url: END_POINTS.UPLOAD.IMAGEUPLOAD,
        method: 'POST',
        body: credentials,
        formData: true
      })
    }),
    imageDelete: builder.mutation<HTTPResponse<any>, any>({
      query: (credentials) => ({
        url: END_POINTS.UPLOAD.DELETE_IMAGE,
        method: 'POST',
        body: credentials.body
      })
    }),
    createGroup: builder.mutation<
      HTTPResponse<any>,
      { params: OrgAndBranchId; body: any }
    >({
      query: (req) => ({
        url: END_POINTS.GROUP_MANAGEMENT.CREATE_GROUP(req.params),
        method: 'POST',
        body: req.body
      })
    }),
    updateGroup: builder.mutation<
      HTTPResponse<any>,
      {
        params: OrgBranchGroupIds;
        body: UpdateGroupRequest | UpdateAttendanceStatus;
      }
    >({
      query: (req: {
        params: OrgBranchGroupIds;
        body: UpdateGroupRequest | UpdateAttendanceStatus;
      }) => ({
        url: END_POINTS.GROUP_MANAGEMENT.UPDATE_GROUP(req.params),
        method: 'PUT',
        body: req.body
      })
    }),
    downloadReport: builder.query<
      HTTPResponse<any>,
      { params: OrgAndBranchId; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.DOWNLOAD_REPORT(req.params)}${
          req.queryString
        }`,
        method: 'GET',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseHandler: (res) => res.blob()
      })
    }),
    createMemberInExistingGroup: builder.mutation<
      any,
      {
        params: OrgBranchGroupIds;
        body: CreateMemberInExistingGroupRequestBody;
      }
    >({
      query: (req) => ({
        url: END_POINTS.GROUP_MANAGEMENT.CREATE_MEMBER_IN_EXISTING_GROUP(
          req.params
        ),
        method: 'PATCH',
        body: req.body
      })
    }),
    getAllPrimaryNumbers: builder.query<any, { params: OrgBranchGroupIds }>({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_ALL_PRIMARY_NUMBERS(
          req.params
        )}`,
        method: 'GET'
      })
    }),
    getOrganizationConfig: builder.query<any, { organizationId: string }>({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_ORGANIZATION_CONFIG(
          req.organizationId
        )}`,
        method: 'GET'
      })
    }),
    getAttendanceList: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupIds; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.ATTENDANCE.GET_ATTENDANCE_LIST(
          req.params.organizationId,
          req.params.branchId,
          req.params.groupId
        )}${req.queryString}`,
        method: 'GET'
      })
    }),
    markUnmarkHoliday: builder.mutation<
      HTTPResponse<any>,
      {
        params: OrgBranchGroupIds;
        body: { date: string; status: DayType };
      }
    >({
      query: (req) => ({
        url: `${END_POINTS.ATTENDANCE.MARK_UNMARK_HOLIDAY(req.params)}`,
        method: 'POST',
        body: req.body
      })
    }),
    downloadAttendanceReport: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupIds; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.ATTENDANCE.DOWNLOAD_ATTENDANCE_REPORT(req.params)}${
          req.queryString
        }`,
        method: 'GET',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseHandler: (res) => res.blob()
      })
    }),
    getGroupMemberDetails: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupMemberIds; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.ATTENDANCE.GET_GROUP_MEMBER_DETAILS(req.params)}${
          req.queryString
        }`,
        method: 'GET'
      })
    }),
    getMemberById: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupMemberIds }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_MEMBER_BY_ID(req.params)}`
      })
    }),
    getMemberContactDetails: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchGroupIds }
    >({
      query: (req) => ({
        url: `${END_POINTS.GROUP_MANAGEMENT.GET_GROUP_MEMBER_DETAILS(
          req.params
        )}`,
        method: 'GET'
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetAllGroupsQuery,
  useLazyGetGroupMembersQuery,
  useDeleteGroupMemberMutation,
  useUpdateMemberMutation,
  useUpdateMemberStatusMutation,
  useLazyGetGroupByIdQuery,
  useUpdateGroupStatusMutation,
  useImageUploadMutation,
  useImageDeleteMutation,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useLazyDownloadReportQuery,
  useCreateMemberInExistingGroupMutation,
  useLazyGetAllPrimaryNumbersQuery,
  useLazyGetOrganizationConfigQuery,
  useGetAttendanceListQuery,
  useLazyGetAttendanceListQuery,
  useMarkUnmarkHolidayMutation,
  useLazyDownloadAttendanceReportQuery,
  useLazyGetGroupMemberDetailsQuery,
  useLazyGetMemberByIdQuery,
  useLazyGetMemberContactDetailsQuery
} = extendedBaseQuery;
