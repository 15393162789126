import { PaymentCollectionType } from '@features/group-management/constants';
import { Member } from './member';
import { TabPill } from './tabs';

export interface Group {
  amount: string;
  id: string;
  image_url?: string;
  interval: string;
  intervalFrequency: number;
  is_active: boolean;
  is_payment_link_sent: boolean;
  is_split_payment: boolean;
  member_count: number;
  name: string;
  pay_due: string;
  pay_received: string;
  pending_count: number;
  end_date?: string;
}

export interface GroupInfoOverviewTabProps {
  yearFilter?: number;
  setYearFilter?: (value: number) => void;
  monthFilter: number;
  setMonthFilter: (value: number) => void;
  paymentList: any;
  collectedValue: number;
  dueValue: number;
  collectionStartedAt: string;
  isLoading?: boolean;
  paymentLinkGeneratedOn: string;
}

export enum InsightCardType {
  COLLECTED = 'collected',
  DUE = 'due'
}

export interface InsightCardProps {
  type: InsightCardType;
  value: number;
  className?: string;
}

export interface GroupInfoCardProps {
  groupDetails: GroupDetailsType;
  isActive: boolean;
  handleGroupStatusChange: (status: boolean) => void;
  isLoading?: boolean;
  isEditGroupOpen: boolean;
  setIsEditGroupOpen: (open: boolean) => void;
  hasGroupEditAccess?: boolean;
  hasGroupDisableAccess?: boolean;
}

export interface FeeConfigurationType {
  branch_fee_category_id: number;
  account_detail_id: number;
  category: string;
  amount: string;
}

export interface MemberDetailsProps {
  member: Member;
  handleClose: () => void;
  handleUpdateMember: (data: MemberListType) => void;
  handleDelete: () => void;
  handleMemberStatusChange: (status: boolean) => void;
  isMemberActive: boolean;
  paymentCollectionData: {
    type: PaymentCollectionType | '';
    intervalFrequency: number;
  };
  hasGroupMemberEditAccess?: boolean;
  isAttendanceTrackingEnabled: boolean;
  isAttendanceMarked: boolean;
  isFeeConfigured?: boolean;
  feeConfigurationDetails?: FeeConfigurationType[];
  groupFrequency: string;
  groupIntervalFrequency: number;
}

export interface MonthArrayType {
  id: number;
  label: string;
}

export interface InsightProps {
  filterValue: number;
  setFilterValue: (val: number) => void;
  collectedValue: number;
  dueValue: number;
  collectionStartedAt: string;
  yearFilter?: number;
}

export type FormDataType = {
  groupPhoto: any;
  groupName: string;
  activationDate: string;
  intervalFrequency: string;
  amount: string;
  paymentInstallment: boolean;
  paymentCollectionType: PaymentCollectionType | '';
  endDate?: string;
  isAttandanceTrackingEnabled?: boolean;
  isSendAttendanceAlertEnabled?: boolean;
  workingDays?: string[];
  isPaymentLinkSent?: boolean;
  collectionStartedAt?: string | null;
};

export type FeeCategoryDataType = {
  id: number;
  label: string;
  value: string;
  isManuallyUpdated: boolean;
};

export type MemberListType = {
  altNumber: string;
  amount: string;
  dueDate: string;
  name: string;
  primaryNumber: string;
  id: string;
  is_payment_link_sent?: boolean;
  is_fee_category_enabled?: boolean;
  feeCategoryData?: FeeCategoryDataType[];
  fee_catagoryInfo_for_edit?: FeeConfigurationType[];
  primaryNumberCountryCode?: string;
  altNumberCountryCode?: string;
  email?: string;
};

export type GroupDetailsType = {
  id: string;
  name: string;
  amount: string;
  frequency: string;
  interval_frequency: number;
  is_recurring: boolean;
  is_active: boolean;
  activation_date?: string;
  member_count: number;
  pay_received?: string;
  pay_due?: string;
  allow_activation_date_update?: boolean;
  payment_list?: any[];
  is_payment_link_sent?: boolean;
  is_split_payment?: boolean;
  next_due_at: string;
  remind_again?: boolean;
  collection_started_at?: string;
  image_url?: string;
  created_at: string;
  end_date: string;
  is_attendance_tracking_enabled?: boolean;
  is_send_attendance_alert_enabled?: boolean;
  is_attendance_marked?: boolean;
  work_days?: string[];
  is_fee_configured: boolean;
  group_fee_amount_configuration?: FeeConfigurationType[];
};

export interface AddMembersProps {
  memberList: MemberListType[];
  handleAddManually: (item: MemberListType) => void;
  handleAddViaFile: (memberList: MemberListType[]) => void;
  paymentCollectionType: PaymentCollectionType | '';
  setMemberList: (memberList: any) => void;
  handleEdit: (memberId: string) => void;
  handleDelete: (memberId: string) => void;
  groupAmount: string;
  groupDueDate?: string;
  groupFrequency?: number;
  selectedTab: TabPill;
  setSelectedTab: (tab: TabPill) => void;
  memberFile: File | undefined;
  setMemberFile: (file: File | undefined) => void;
  isCategoryEnabled?: boolean;
  feeCategoryData?: {
    id: number;
    category: string;
    account_detail_id: number;
  }[];
  feeCategories?: FeeCategoryDataType[];
}

export interface ImportFileTabProps {
  handleAdd: (memberList: any) => void;
  groupAmount?: string;
  groupDueDate?: string;
  memberFile?: File | undefined;
  setMemberFile?: (file: File | undefined) => void;
  groupFeeConfigurations?: {
    id?: string | number;
    category: string;
    amount?: string;
    account_detail_id?: string | number;
    branch_fee_category_id?: string | number;
  }[];
  isOnce: boolean;
}

export interface AddManuallyTabProps {
  handleAdd: () => void;
  columnHeaderList: { Header: string; accessor: string; style?: string }[];
  memberList: MemberListType[];
  handleDelete: (memberId: string) => void;
  handleEdit: (memberId: string) => void;
  maxTableHeight?: string;
  infiniteScrollRequired?: boolean;
  setPage?: (prevPage: any) => void;
  isLoading?: boolean;
  disablePagination?: boolean;
  groupAmount?: string;
  groupDueDate?: string;
  groupFrequency?: number;
}

export enum AddMemberModalType {
  ADD = 'add',
  EDIT = 'edit'
}

export interface AddMemberProps {
  type?: AddMemberModalType;
  isOpen: boolean;
  handleCancel: () => void;
  addMember: (data: MemberListType) => void;
  paymentCollectionType: PaymentCollectionType | '';
  selectedmember?: MemberListType | null;
  groupAmount?: string;
  groupDueDate?: string;
  groupFrequency?: number;
  isFeeCategoryEnabled?: boolean;
  feeCategoryData?: {
    id: number;
    category: string;
    account_detail_id: number;
    value?: string;
  }[];
  feeCategoriesValues?: FeeCategoryDataType[];
  memberList: MemberListType[];
}

export type EditGroupType = {
  groupPhoto: { file?: any; preview?: any; image_url?: string };
  name: string;
  amount: string;
  next_due_at: string;
  frequency: string;
  interval_frequency: number;
  paymentInstallment?: boolean;
  end_date: string;
  isAttandanceTrackingEnabled?: boolean;
  isSendAttendanceAlertEnabled?: boolean;
  workingDays?: string[];
};

export interface EditGroupProps {
  isOpen: boolean;
  handleCancel: () => void;
  groupDetails: GroupDetailsType;
  onSaveEditGroup: (data: EditGroupType) => void;
}

export type DueDateOptions = 'next' | 'current';
