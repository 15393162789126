module.exports = {
  theme: 'var(--color-theme)',
  bgBase: 'var(--color-bgBase)',
  content: 'var(--color-content)',
  content1: 'var(--color-content1)',
  content2: 'var(--color-content2)',
  content3: 'var(--color-content3)',
  contentLite: 'var(--color-contentLite)',
  subText: 'var(--color-subText)',
  grey: 'var(--color-grey)',
  grey90: 'var(--color-grey90)',
  grey80: 'var(--color-grey80)',
  grey60: 'var(--color-grey60)',
  grey70: 'var(--color-grey70)',
  grey50: 'var(--color-grey50)',
  grey40: 'var(--color-grey40)',
  grey30: 'var(--color-grey30)',
  grey20: 'var(--color-grey20)',
  grey10: 'var(--color-grey10)',
  primary: 'var(--color-primary)',
  primary10: 'var(--color-primary10)',
  primary20: 'var(--color-primary20)',
  primary30: 'var(--color-primary30)',
  primary40: 'var(--color-primary40)',
  primary50: 'var(--color-primary50)',
  primary60: 'var(--color-primary60)',
  primary70: 'var(--color-primary70)',
  primary80: 'var(--color-primary80)',
  primary90: 'var(--color-primary90)',
  primaryLite: 'var(--color-primaryLite)',
  primaryLite1: 'var(--color-primaryLite1)',
  primaryLite2: 'var(--color-primaryLite2)',
  primaryLite3: 'var(--color-primaryLite3)',
  primaryLite4: 'var(--color-primaryLite4)',
  primaryLite5: 'var(--color-primaryLite5)',
  primaryLite6: 'var(--color-primaryLite6)',
  primaryLite7: 'var(--color-primaryLite7)',
  primaryLite8: 'var(--color-primaryLite8)',
  primaryLite9: 'var(--color-primaryLite9)',
  primaryLiteBg: 'var(--color-primaryLiteBg)',
  error: 'var(--color-error)',
  error10: 'var(--color-error10)',
  error20: 'var(--color-error20)',
  error30: 'var(--color-error30)',
  error40: 'var(--color-error40)',
  error50: 'var(--color-error50)',
  errorLite: 'var(--color-errorLite)',
  errorLite1: 'var(--color-errorLite1)',
  errorLite2: 'var(--color-errorLite2)',
  errorLite3: 'var(--color-errorLite3)',
  errorLite4: 'var(--color-errorLite4)',
  errorLite5: 'var(--color-errorLite5)',
  disabledContent: 'var(--color-disabledContent)',
  borderGrey: 'var(--color-borderGrey)',
  borderGrey1: 'var(--color-borderGrey1)',
  borderGrey2: 'var(--color-borderGrey2)',
  borderGrey3: 'var(--color-borderGrey3)',
  borderGrey4: 'var(--color-borderGrey4)',
  bgGrey: 'var(--color-bgGrey)',
  bgGrey10: 'var(--color-bgGrey10)',
  bgGrey20: 'var(--color-bgGrey20)',
  bgGrey30: 'var(--color-bgGrey30)',
  bgGrey40: 'var(--color-bgGrey40)',
  bgGrey50: 'var(--color-bgGrey50)',
  bgGrey60: 'var(--color-bgGrey60)',
  bgGrey70: 'var(--color-bgGrey70)',
  bgGrey80: 'var(--color-bgGrey80)',
  bgGrey90: 'var(--color-bgGrey90)',
  neutral: 'var(--color-neutral)',
  greyStroke: 'var(--color-greyStroke)',
  greyBorder: 'var(--color-greyBorder)',
  transparentGrey: 'var(--color-transparentGrey)',
  verifiedGreen: 'var(--color-verifiedGreen)',
  darkGrey: 'var(--color-darkGrey)',
  selectionPrimary: 'var(--color-selectionPrimary)',
  whiteBorder: 'var(--color-whiteBorder)',
  yellow: 'var(--color-yellow)',
  yellow10: 'var(--color-yellow10)',
  yellow20: 'var(--color-yellow20)',
  yellow30: 'var(--color-yellow30)',
  yellow40: 'var(--color-yellow40)',
  yellow50: 'var(--color-yellow50)',
  yellow60: 'var(--color-yellow60)',
  yellowLite: 'var(--color-yellowLite)',
  yellowLite1: 'var(--color-yellowLite1)',
  yellowLite2: 'var(--color-yellowLite2)',
  grey1: 'var(--color-grey1)',
  grey2: 'var(--color-grey2)',
  blueGrey: 'var(--color-blueGrey)',
  warningYellow: 'var(--color-warningYellow)',
  meroon: 'var(--color-meroon)',
  blue: 'var(--color-blue)',
  blue1: 'var(--color-blue1)',
  blue2: 'var(--color-blue2)',
  blue3: 'var(--color-blue3)',
  blue4: 'var(--color-blue4)'
};
