import { ReactComponent as GroupsIcon } from '@assets/images/groups-icon.svg';
import { ReactComponent as BranchesIcon } from '@assets/images/branches-icon.svg';
import { ReactComponent as TeamManagementIcon } from '@assets/images/team-management-icon.svg';
import { ReactComponent as AdminIcon } from '@assets/images/admin-icon.svg';
import { ReactComponent as HelpIcon } from '@assets/images/customer-support.svg';
import { ReactComponent as DashboardIcon } from '@assets/images/dashboard.svg';
import { ReactComponent as TransactionsIcon } from '@assets/images/transactions-icon.svg';
import { ReactComponent as SubscriptionIcon } from '@assets/images/subscription-icon.svg';
import { ReactComponent as ReportsIcon } from '@assets/images/reports-icon.svg';
// import { ReactComponent as OrgSettingsIcon }
// from '@assets/images/org-settings.svg';
import {
  BRANCHES,
  ADMIN,
  TEAM_MANAGEMENT,
  HELP,
  DASHBOARD,
  TRANSACTIONS,
  SUBSCRIPTION_PLAN,
  REPORTS
  // ORG_SETTINGS
} from '@constants/routes';
import { getGroupsHome } from '@utils/generic';

import UserPermissionTypes from './permissions';

export const groupsTabPathPattern =
  /^\/branches\/brch_[A-Za-z0-9]*\/groups(\/grp_[A-Za-z0-9]*)?$/;

const INDIVIDUAL_MENU_ITEMS = [
  {
    id: 'groups',
    Icon: GroupsIcon,
    label: 'Groups',
    route: getGroupsHome()
  },
  {
    id: 'transactions',
    Icon: TransactionsIcon,
    label: 'Transactions',
    route: TRANSACTIONS.TRANSACTIONS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_VIEW]
  },
  {
    id: 'report',
    Icon: ReportsIcon,
    label: 'Reports',
    route: REPORTS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_GENERATE_GST_REPORT]
  },
  {
    id: 'subscriptions',
    Icon: SubscriptionIcon,
    label: 'Plan & Billing',
    route: SUBSCRIPTION_PLAN,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  }
];

const ORG_MENU_ITEMS = [
  {
    id: 'dashboard',
    Icon: DashboardIcon,
    label: 'Dashboard',
    route: DASHBOARD,
    permissions: [UserPermissionTypes.DASHBOARD_VIEW]
  },
  {
    id: 'branches',
    Icon: BranchesIcon,
    label: 'Branches',
    route: BRANCHES.BRANCHES,
    permissions: [UserPermissionTypes.BRANCH_VIEW]
  },
  {
    id: 'groups',
    Icon: GroupsIcon,
    label: 'Groups',
    route: getGroupsHome(),
    permissions: [UserPermissionTypes.GROUP_VIEW]
  },
  {
    id: 'team-management',
    Icon: TeamManagementIcon,
    label: 'Team Management',
    route: TEAM_MANAGEMENT.TEAM_MANAGEMENT,
    permissions: [
      UserPermissionTypes.ORG_ADMIN_VIEW,
      UserPermissionTypes.OWNER_VIEW,
      UserPermissionTypes.BRANCH_ADMIN_VIEW,
      UserPermissionTypes.GROUP_ADMIN_VIEW
    ]
  },
  {
    id: 'transactions',
    Icon: TransactionsIcon,
    label: 'Transactions',
    route: TRANSACTIONS.TRANSACTIONS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_VIEW]
  },
  {
    id: 'report',
    Icon: ReportsIcon,
    label: 'Reports',
    route: REPORTS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_GENERATE_GST_REPORT]
  },
  {
    id: 'subscriptions',
    Icon: SubscriptionIcon,
    label: 'Plan & Billing',
    route: SUBSCRIPTION_PLAN,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  }
  // Todo: Update the permissions for the below menu item
  // {
  //   id: 'org-settings',
  //   Icon: OrgSettingsIcon,
  //   label: 'Settings',
  //   route: ORG_SETTINGS,
  //   permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  // }
];

const ADMIN_MENU_ITEMS = [
  {
    id: 'kyc-documents',
    Icon: AdminIcon,
    label: 'KYC Documents',
    route: ADMIN.KYC_DOCUMENTS
  }
];

const HELP_ITEM = {
  id: 'help',
  Icon: HelpIcon,
  label: 'Help',
  route: HELP
};

export { INDIVIDUAL_MENU_ITEMS, ORG_MENU_ITEMS, ADMIN_MENU_ITEMS, HELP_ITEM };
